import React from "react"
import styled from "@emotion/styled"
import { Row, Col, Container } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebookSquare,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { colors } from "../helpers/colors"

const BaseFooter = styled.div`
  display: flex;
  vertical-align: middle;
  background-color: ${colors.bluedark};
  width: 100%;
  height: auto;
  margin: 30px 0;
`

const IconLinkStyled = styled(OutboundLink)`
  margin: 5px;
  padding: 5px;
  // border: 1px solid red;
`

const FontAwesomeIconStyled = styled(FontAwesomeIcon)`
  &:hover {
    color: ${colors.kdyellow};
  }
`

// const FooterText = styled.span`
//   font-family: Roboto;
//   font-size: 0.6em;
//   color: #707070;
// `

const Footer = () => {
  return (
    <BaseFooter>
      <Container
        className="text-center"
        style={{ marginTop: "auto", marginBottom: "auto" }}
      >
        <Row>
          <Col>
            <IconLinkStyled
              href="https://www.facebook.com/kraftdinner/"
              target="_blank"
            >
              <FontAwesomeIconStyled
                icon={faFacebookSquare}
                color="white"
                size="lg"
              />
            </IconLinkStyled>
            <IconLinkStyled
              href="https://www.instagram.com/kraftdinnerca/"
              target="_blank"
            >
              <FontAwesomeIconStyled
                icon={faInstagram}
                color="white"
                size="lg"
              />
            </IconLinkStyled>
            <IconLinkStyled
              href="https://twitter.com/kraftdinner"
              target="_blank"
            >
              <FontAwesomeIconStyled icon={faTwitter} color="white" size="lg" />
            </IconLinkStyled>
          </Col>
        </Row>
      </Container>
    </BaseFooter>
  )
}

export default Footer
