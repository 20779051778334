/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Container } from "react-bootstrap"
import styled from "@emotion/styled"

import "bootstrap/dist/css/bootstrap.min.css"
import "react-perfect-scrollbar/dist/css/styles.css"

import Footer from "./footer"
import "./layout.css"

import BackgroundSVG from "../images/background.svg"

const BackgroundStyled = styled.div`
  width: 100%;
  height: auto;
  background-color: #144399;
  background-image: url(${BackgroundSVG});
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: bottom center;
`

const ContainerStyled = styled(Container)`
  // background-color: #144399;
`

const Layout = ({ children }) => {
  return (
    <>
      <span id="Top" />
      <BackgroundStyled>
        <ContainerStyled>
          <main>{children}</main>
        </ContainerStyled>
      </BackgroundStyled>
      <Footer></Footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
